<template>
	<div class="h-full">
		<el-dialog title="库存数量分配" :visible.sync="config.dialogFormVisible">
			<el-table :data="config.DataList" style="width: 100%" border>
				<el-table-column fixed prop="NormalNum" label="已分配库存" width="150">
					<template slot-scope="scope">
						<el-row>
							<el-input type="number" size="small" v-model="scope.row.NormalNum" placeholder="请输入数字" ></el-input>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="StockSum" label="库存数量" width="120">
				</el-table-column>
				<el-table-column prop="UnallocatedCount" label="未分配库存" width="120">
				</el-table-column>
				<el-table-column prop="WarehouseName" label="仓库名称" width="120">
				</el-table-column>
				<el-table-column prop="ProductCode" label="商品编码" width="120">
				</el-table-column>
				<el-table-column prop="ProductName" label="商品名称" width="120">
				</el-table-column>
				<el-table-column prop="SKU" label="规格" width="120">
				</el-table-column>
				<el-table-column prop="ProductBarCode" label="条码" width="120">
				</el-table-column>
				<el-table-column prop="ProductWholeUnit" label="单位" width="120">
				</el-table-column>
				<el-table-column prop="DisplayInventoryType" label="库存状态" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr1" label="批属性1" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr2" label="批属性2" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr3" label="批属性3" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr4" label="批属4" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr5" label="批属性5" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr6" label="批属性6" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr7" label="批属性7" width="120">
				</el-table-column>
				<el-table-column prop="LotAttr8" label="批属性8" width="120">
				</el-table-column>				
				<el-table-column prop="UserAccount" label="用户账户" width="120">
				</el-table-column>
				<el-table-column prop="UserName" label="账户名称" width="120">
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="config.dialogFormVisible=false">取 消</el-button>
				<el-button type="primary" @click="sumbitStockNumber">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		mounted() {},
		data() {
			return {
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					params: {}
				},

			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				dialogFormVisible: false,
				DataList: []
			}

		},
		methods: {
			sumbitStockNumber: function() {
				var _this = this;
				var parms = {
					StockNumbers: []
				};


				_this.config.DataList.forEach(item => {
					var number = 0;
					if (item.NormalNum != null && item.NormalNum != "") {
						number = item.NormalNum;
					}

					parms.StockNumbers.push({
						StockQuotaID: item.StockQuotaID,
						HaveCount: number
					});
				});
				_this.$ajax.send("omsapi/stock/stockrelation/updateuserstock", "post", parms, (result) => {
					_this.$alert("分配成功！", '库存分配信息提示', {
						confirmButtonText: '确定',
						callback: action => {
							_this.Event.$emit("stockRelationNumberList");
						}
					});


				}, err => {
					_this.$alert(err, '库存分配信息提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
				});


			},
			//修改库存
			handleEdit: function(index, row) {
				if (row.UnallocatedCount < parseFloat(row.NormalNum)) {
					row.NormalNum = 0;
				}
				//同一个库存下库存数据 查询集合(批属性,商品编码，仓库，商品类型，生产批次，生产日期)
				var list = this.config.DataList.filter(f => f.ProductNo == row.ProductNo && f.WarehouseNo == row.WarehouseNo && f.InventoryType ==
					row.InventoryType &&
					f.ProductBatchNo == row.ProductBatchNo && f.ProductDate == row.ProductDate && f.LotAttr1 == row.LotAttr1 && f.LotAttr2 ==
					row.LotAttr2 &&
					f.LotAttr3 == row.LotAttr3 && f.LotAttr4 == row.LotAttr4 && f.LotAttr5 == row.LotAttr5 && f.LotAttr6 == row.LotAttr6 &&
					f.LotAttr7 == row.LotAttr7 &&
					f.LotAttr8 == row.LotAttr8 && f.LotAttr9 == row.LotAttr9 && f.LotAttr10 == row.LotAttr10 && f.LotAttr11 == row.LotAttr11 &&
					f.LotAttr12 == row.LotAttr12);
				//同一个库存下库存数据,用户库存合
				var nuberSum = 0;
				list.forEach(item => {
					if (item.NormalNum != null && item.NormalNum != "") {
						nuberSum += parseFloat(item.NormalNum);
					}
				});
				if (row.UnallocatedCount > 0) {
					var surplus = row.StockSum - nuberSum;
					if (surplus >= 0) {
						list.forEach(item => {
							item.UnallocatedCount = surplus;
						});
					} else {
						row.NormalNum = 0;
					}
				}else{
					
				}
			}
		},

	}
</script>
